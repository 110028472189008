import React, { useEffect } from "react";
import Layout from "../components/layout";
import { navigate } from "gatsby";
import SEO from "../components/seo";

const NotFoundPage = () => {
  useEffect(() => {
    navigate("/");
  }, []);
  return (
    <div>
      <SEO pagetitle="ページが見つかりません" />
      <Layout>
        <div style={{ padding: "20vh 0", textAlign: "center" }}>
          <h1>お探しのページは見つかりません。</h1>
        </div>
      </Layout>
    </div>
  );
};

export default NotFoundPage;
